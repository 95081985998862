import {mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                binotel_api_key: '',
                binotel_secret_key: '',
                binotel_tehnical_phone:'',
            },
        }
    },

    computed: {
        ...mapGetters({
            options:'config/options',
        }),
    },
    created() {
        this.checkOption(this.options);
    },
    methods: {
        checkOption(res){
            if(res){
                res.models.binotel_api_key ? this.form.binotel_api_key = res.models.binotel_api_key : this.form.binotel_api_key = '';
                res.models.binotel_secret_key ? this.form.binotel_secret_key = res.models.binotel_secret_key : this.form.binotel_secret_key = '';
            }
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendConfirm() {
            if(this.warehouse){
                this.$emit('changeOptions', this.form)
            }else{
                this.$emit('sendOptions', this.form)
            }
        },
        ...mapMutations({
            changeShowWarehouse: 'warehouse/changeShowWarehouse'
        })
    },
    destroyed() {
        this.changeShowWarehouse(null);
    }
}
